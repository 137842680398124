<template>
  <page-view class="metaverse" :up-class="'metaverse'">
    <div class="baner-img">
      <iframe src="https://zzx-1312093770.cos.ap-nanjing.myqcloud.com/uwebgl/bcmeta/index.html" frameborder=0 allowfullscreen
        height='100%' width='100%'></iframe>
      <div class="info-box">
        <div class="info-name">元宇宙数字展馆</div>
        <div class="info-describe">
          用户随时随地浏览企业展厅，
          <br />
          并能获得更沉浸的逛展体验
          <div class="info-btn" @click="viewHall">>>>进入展馆</div>
        </div>
      </div>
    </div>
    <div class="metaverse-content">
      <el-carousel :height="isMobile ? '13.5rem' : '740px'" :autoplay="false" :interval="5000" arrow="always"
        trigger="click" @change="changeMetaverse">
        <el-carousel-item v-for="item in listSize" :key="item">
          <div class="metaverse-list">
            <div class="item" v-for="(item, index) in metaverseList" :key="index" @click="toDetail(item)">
              <img :src="item.image" alt="" />
              <div class="item-txt">
                <div class="txt-name">{{ item.title }}</div>
                <div class="txt-describe">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Metaverse",
  data() {
    return {
      metaverseList: [],
      listSize: 2,
      listNum: 3,
      listIndex: 0,
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getArticleList();
  },
  mounted() { },
  methods: {
    getArticleList() {
      let listNum = this.isMobile ? 1 : this.listNum;
      axios
        .get(
          `https://zzx.zhenonline.com//mobile/article/list?type=16&categoryId=334&index=${this.listIndex}&size=${listNum}`
        )
        .then((res) => {
          this.metaverseList = res.data.data.list;
          this.listSize = Math.ceil(res.data.data.size / listNum);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeMetaverse(v) {
      this.listIndex = v;
      this.getArticleList();
    },
    toDetail(item) {
      const openRoute = this.$router.resolve({
        name: "MetaverseDetail",
        params: { id: item.id },
      });
      window.open(openRoute.href, "_blank");
    },
    //查看展厅
    viewHall() {
      window.open("https://zzx-1312093770.cos.ap-nanjing.myqcloud.com/uwebgl/bczg/index.html");
    }
  },
};
</script>
<style lang="scss" scoped>
.transferTreeBox {
  display: flex;
  width: 590px;
  justify-content: space-around;

  .SelectBox {
    border: 1px solid #ccc;
    height: 270px;
    width: 200px;
    color: #fff;
    position: relative;

    .boxTitle {
      background: #a0cfff;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
    }

    .boxCenter {
      height: 100%;
      width: 100%;
      max-height: 239px;
      overflow-y: scroll;
    }
  }

  .transferBtn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pickBtn {
      height: 30px;
      width: 50px;
      background: #a0cfff;
      color: #fff;
      font-weight: 700;
      font-size: 20px;
      border-radius: 5px;
      margin-top: 10px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" scoped>
.metaverse {
  .water-box {
    width: 80%;
    margin: 20px auto;
  }

  .baner-img {
    height: 800px;
    position: relative;

    // cursor: pointer;展厅不跳转
    @media screen and (max-width: 1200px) {
      height: 3.87rem;
    }

    >img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .info-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10%;
      @include flexbox(column, flex-start, flex-start);

      .info-name {
        font-weight: bold;
        color: #fff;
        font-size: 66px;

        @media screen and (max-width: 1200px) {
          font-size: 0.5rem;
        }
      }

      .info-describe {
        color: #fff;
        font-size: 16px;
        margin: 50px 0;
        position: relative;
        .info-btn {
          position: absolute;
          top: 50%;
          right: -60%;
          transform: translateY(-50%);
          font-size: 16px;
          font-weight: bold;
          color: #439C4C;
          cursor: pointer;
        }
      }
    }
  }

  .metaverse-content {
    padding-bottom: 8vw;
    background-color: #19141a;

    :deep(.el-carousel) {
      .el-carousel__arrow {
        font-size: 28px;
        @include flexbox(row, center, center);
        height: 40px;
        width: 40px;
        background-color: rgba(31, 45, 61, 0.71);
      }

      >ul {
        width: 100%;
        text-align: center;
      }
    }

    .metaverse-list {
      padding: 100px 0;
      @include flexbox(row, space-evenly, center);

      .item {
        width: 20%;
        @include flexbox(column);
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
          width: 88%;
        }

        >img {
          width: 100%;
          height: 300px;

          @media screen and (max-width: 1200px) {
            height: 5rem;
          }
        }

        .item-txt {
          color: #fff;
          background-color: #24212a;
          padding: 10px;
          box-sizing: border-box;
          min-height: 240px;

          .txt-name {
            font-size: 22px;
            margin: 20px 0;
          }

          .txt-describe {
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
